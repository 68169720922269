import list from 'modules/routePacking/list/routeListReducers';
import form from 'modules/routePacking/form/routeFormReducers';
import view from 'modules/routePacking/view/routeViewReducers';
import destroy from 'modules/routePacking/destroy/routeDestroyReducers';
import importerReducer from 'modules/routePacking/importer/routeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
