import Permissions from 'security/permissions';
// @ts-ignore
import { i18n } from 'i18n';
const permissions = Permissions.values;

const privateRoutes = [
  {
    menuGroup: {
      isGroup: false,
      item: [{
        path: '/',
        icon: 'home',
        label: i18n('home.menu'),
        menu: {
          exact: true,
        },
        loader: () => import('view/home/HomePage'),
        permissionRequired: null,
        exact: true,
      }]
    },
  },

  {
    menuGroup: {
      isGroup: false,
      item: [{
        path: '/profile',
        loader: () => import('view/auth/ProfileFormPage'),
        permissionRequired: null,
        exact: true,
        menu: false,
      }],
    },
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/customer',
          loader: () => import('view/customer/list/CustomerListPage'),
          permissionRequired: permissions.customerRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.customer.menu'),
          menu: true,
        },
        {
          path: '/customer/new',
          loader: () => import('view/customer/form/CustomerFormPage'),
          menu: false,
          permissionRequired: permissions.customerCreate,
          exact: true,
        },
        {
          path: '/customer/importer',
          loader: () =>
            import('view/customer/importer/CustomerImporterPage'),
          menu: false,
          permissionRequired: permissions.customerImport,
          exact: true,
        },
        {
          path: '/customer/:id/edit/:idOrder?',
          loader: () => import('view/customer/form/CustomerFormPage'),
          menu: false,
          permissionRequired: permissions.customerEdit,
          exact: true,
        },
        {
          path: '/customer/:id',
          loader: () => import('view/customer/view/CustomerViewPage'),
          menu: false,
          permissionRequired: permissions.customerRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/product',
          loader: () => import('view/product/list/ProductListPage'),
          permissionRequired: permissions.productRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.product.menu'),
          menu: true,
          menuGroup: 'Produto',
        },
        {
          path: '/product/new',
          loader: () => import('view/product/form/ProductFormPage'),
          menu: false,
          permissionRequired: permissions.productCreate,
          exact: true,
        },
        {
          path: '/product/importer',
          loader: () =>
            import('view/product/importer/ProductImporterPage'),
          menu: false,
          permissionRequired: permissions.productImport,
          exact: true,
        },
        {
          path: '/product/:id/edit',
          loader: () => import('view/product/form/ProductFormPage'),
          menu: false,
          permissionRequired: permissions.productEdit,
          exact: true,
        },
        {
          path: '/product/:id',
          loader: () => import('view/product/view/ProductViewPage'),
          menu: false,
          permissionRequired: permissions.productRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/order',
          loader: () => import('view/order/list/OrderListPage'),
          permissionRequired: permissions.orderRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.order.menu'),
          menu: true,
        },
        {
          path: '/order/new',
          loader: () => import('view/order/form/OrderFormPage'),
          menu: false,
          permissionRequired: permissions.orderCreate,
          exact: true,
        },
        {
          path: '/order/importer',
          loader: () =>
            import('view/order/importer/OrderImporterPage'),
          menu: false,
          permissionRequired: permissions.orderImport,
          exact: true,
        },
        {
          path: '/order/:id/edit',
          loader: () => import('view/order/form/OrderFormPage'),
          menu: false,
          permissionRequired: permissions.orderEdit,
          exact: true,
        },
        {
          path: '/order/:id',
          loader: () => import('view/order/view/OrderViewPage'),
          menu: false,
          permissionRequired: permissions.orderRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/payment',
          loader: () => import('view/payment/list/PaymentListPage'),
          permissionRequired: permissions.paymentRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.payment.menu'),
          menu: true,
        }
      ]
    }
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/company',
          loader: () => import('view/company/list/CompanyListPage'),
          permissionRequired: permissions.companyRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.company.menu'),
          menu: true,
        },
        {
          path: '/company/new',
          loader: () => import('view/company/form/CompanyFormPage'),
          menu: false,
          permissionRequired: permissions.companyCreate,
          exact: true,
        },
        {
          path: '/company/importer',
          loader: () =>
            import('view/company/importer/CompanyImporterPage'),
          menu: false,
          permissionRequired: permissions.companyImport,
          exact: true,
        },
        {
          path: '/company/:id/edit',
          loader: () => import('view/company/form/CompanyFormPage'),
          menu: false,
          permissionRequired: permissions.companyEdit,
          exact: true,
        },
        {
          path: '/company/:id',
          loader: () => import('view/company/view/CompanyViewPage'),
          menu: false,
          permissionRequired: permissions.companyRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/webhook',
          loader: () => import('view/webhook/list/WebhookListPage'),
          permissionRequired: permissions.webhookRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.webhook.menu'),
          menu: true,
        },
        {
          path: '/webhook/new',
          loader: () => import('view/webhook/form/WebhookFormPage'),
          menu: false,
          permissionRequired: permissions.webhookCreate,
          exact: true,
        },
        {
          path: '/webhook/importer',
          loader: () =>
            import('view/webhook/importer/WebhookImporterPage'),
          menu: false,
          permissionRequired: permissions.webhookImport,
          exact: true,
        },
        {
          path: '/webhook/:id/edit',
          loader: () => import('view/webhook/form/WebhookFormPage'),
          menu: false,
          permissionRequired: permissions.webhookEdit,
          exact: true,
        },
        {
          path: '/webhook/:id',
          loader: () => import('view/webhook/view/WebhookViewPage'),
          menu: false,
          permissionRequired: permissions.webhookRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/ccm',
          loader: () => import('view/ccm/list/CcmListPage'),
          permissionRequired: permissions.ccmRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.ccm.menu'),
          menu: true,
        },
        {
          path: '/ccm/new',
          loader: () => import('view/ccm/form/CcmFormPage'),
          menu: false,
          permissionRequired: permissions.ccmCreate,
          exact: true,
        },
        {
          path: '/ccm/importer',
          loader: () =>
            import('view/ccm/importer/CcmImporterPage'),
          menu: false,
          permissionRequired: permissions.ccmImport,
          exact: true,
        },
        {
          path: '/ccm/:id/edit',
          loader: () => import('view/ccm/form/CcmFormPage'),
          menu: false,
          permissionRequired: permissions.ccmEdit,
          exact: true,
        },
        {
          path: '/ccm/:id',
          loader: () => import('view/ccm/view/CcmViewPage'),
          menu: false,
          permissionRequired: permissions.ccmRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/qrcodeTemplate',
          loader: () => import('view/qrcodeTemplate/list/QrcodeTemplateListPage'),
          permissionRequired: permissions.qrcodeTemplateRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.qrcodeTemplate.menu'),
          menu: true,
        },
        {
          path: '/qrcodeTemplate/new',
          loader: () => import('view/qrcodeTemplate/form/QrcodeTemplateFormPage'),
          menu: false,
          permissionRequired: permissions.qrcodeTemplateCreate,
          exact: true,
        },
        {
          path: '/qrcodeTemplate/importer',
          loader: () =>
            import('view/qrcodeTemplate/importer/QrcodeTemplateImporterPage'),
          menu: false,
          permissionRequired: permissions.qrcodeTemplateImport,
          exact: true,
        },
        {
          path: '/qrcodeTemplate/:id/edit',
          loader: () => import('view/qrcodeTemplate/form/QrcodeTemplateFormPage'),
          menu: false,
          permissionRequired: permissions.qrcodeTemplateEdit,
          exact: true,
        },
        {
          path: '/qrcodeTemplate/:id',
          loader: () => import('view/qrcodeTemplate/view/QrcodeTemplateViewPage'),
          menu: false,
          permissionRequired: permissions.qrcodeTemplateRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: true,
      label: i18n('subMenu.warehouse'),
      icon: 'menu',
      item: [
        {
          path: '/warehouse',
          loader: () => import('view/warehouse/list/WarehouseListPage'),
          permissionRequired: permissions.warehouseRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.warehouse.menu'),
          menu: true,
          menuGroup: i18n('subMenu.warehouse'),
        },
        {
          path: '/warehouse/new',
          loader: () => import('view/warehouse/form/WarehouseFormPage'),
          menu: false,
          permissionRequired: permissions.warehouseCreate,
          exact: true,
        },
        {
          path: '/warehouse/importer',
          loader: () =>
            import('view/warehouse/importer/WarehouseImporterPage'),
          menu: false,
          permissionRequired: permissions.warehouseImport,
          exact: true,
        },
        {
          path: '/warehouse/:id/edit',
          loader: () => import('view/warehouse/form/WarehouseFormPage'),
          menu: false,
          permissionRequired: permissions.warehouseEdit,
          exact: true,
        },
        {
          path: '/warehouse/:id',
          loader: () => import('view/warehouse/view/WarehouseViewPage'),
          menu: false,
          permissionRequired: permissions.warehouseRead,
          exact: true,
        },
        {
          path: '/inbound',
          loader: () => import('view/inbound/list/InboundListPage'),
          permissionRequired: permissions.inboundRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.inbound.menu'),
          menu: true,
          menuInbound: i18n('subMenu.warehouse'),
        },
        {
          path: '/inbound/new',
          loader: () => import('view/inbound/form/InboundFormPage'),
          menu: false,
          permissionRequired: permissions.inboundCreate,
          exact: true,
        },
        {
          path: '/inbound/:id',
          loader: () => import('view/inbound/view/InboundViewPage'),
          menu: false,
          permissionRequired: permissions.inboundRead,
          exact: true,
        },
        {
          path: '/packing',
          loader: () => import('view/packing/list/PackingListPage'),
          permissionRequired: permissions.packingRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.packing.menu'),
          menu: true,
          menuPacking: i18n('subMenu.warehouse'),
        },
        {
          path: '/packing/new',
          loader: () => import('view/packing/form/PackingFormPage'),
          menu: false,
          permissionRequired: permissions.packingCreate,
          exact: true,
        },
        {
          path: '/packing/:id',
          loader: () => import('view/packing/view/PackingViewPage'),
          menu: false,
          permissionRequired: permissions.packingRead,
          exact: true,
        },
        {
          path: '/route',
          loader: () => import('view/routePacking/list/RouteListPage'),
          permissionRequired: permissions.routeRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.route.menu'),
          menu: true,
          menuPacking: i18n('subMenu.warehouse'),
        },
        {
          path: '/route/new',
          loader: () => import('view/routePacking/form/RouteFormPage'),
          menu: false,
          permissionRequired: permissions.routeCreate,
          exact: true,
        },
        {
          path: '/route/:id/edit',
          loader: () => import('view/routePacking/form/RouteFormPage'),
          menu: false,
          permissionRequired: permissions.routeEdit,
          exact: true,
        },
        {
          path: '/route/:id',
          loader: () => import('view/routePacking/view/RouteViewPage'),
          menu: false,
          permissionRequired: permissions.routeRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: false,
      item: [
        {
          path: '/building',
          loader: () => import('view/building/list/BuildingListPage'),
          permissionRequired: permissions.buildingRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.building.menu'),
          menu: true,
        },
        {
          path: '/building/new',
          loader: () => import('view/building/form/BuildingFormPage'),
          menu: false,
          permissionRequired: permissions.buildingCreate,
          exact: true,
        },
        {
          path: '/building/importer',
          loader: () =>
            import('view/building/importer/BuildingImporterPage'),
          menu: false,
          permissionRequired: permissions.buildingImport,
          exact: true,
        },
        {
          path: '/building/:id/edit',
          loader: () => import('view/building/form/BuildingFormPage'),
          menu: false,
          permissionRequired: permissions.buildingEdit,
          exact: true,
        },
        {
          path: '/building/:id',
          loader: () => import('view/building/view/BuildingViewPage'),
          menu: false,
          permissionRequired: permissions.buildingRead,
          exact: true,
        },

        // {
        //   path: '/building-level1',
        //   loader: () => import('view/buildingLevel1/list/BuildingLevel1ListPage'),
        //   permissionRequired: permissions.buildingLevel1Read,
        //   exact: true,
        //   icon: 'right',
        //   label: i18n('entities.buildingLevel1.menu'),
        //   menu: false
        // },
        // {
        //   path: '/building-level1/new',
        //   loader: () => import('view/buildingLevel1/form/BuildingLevel1FormPage'),
        //   menu: false,
        //   permissionRequired: permissions.buildingLevel1Create,
        //   exact: true,
        // },
        // {
        //   path: '/building-level1/importer',
        //   loader: () =>
        //     import('view/buildingLevel1/importer/BuildingLevel1ImporterPage'),
        //   menu: false,
        //   permissionRequired: permissions.buildingLevel1Import,
        //   exact: true,
        // },
        {
          path: '/building-level1/:id/edit',
          loader: () => import('view/buildingLevel1/form/BuildingLevel1FormPage'),
          menu: false,
          permissionRequired: permissions.buildingLevel1Edit,
          exact: true,
        },
        {
          path: '/building-level1/:id',
          loader: () => import('view/buildingLevel1/view/BuildingLevel1ViewPage'),
          menu: false,
          permissionRequired: permissions.buildingLevel1Read,
          exact: true,
        },

        // {
        //   path: '/building-level2',
        //   loader: () => import('view/buildingLevel2/list/BuildingLevel2ListPage'),
        //   permissionRequired: permissions.buildingLevel2Read,
        //   exact: true,
        //   icon: 'right',
        //   label: i18n('entities.buildingLevel2.menu'),
        //   menu: false,
        // },
        // {
        //   path: '/building-level2/new',
        //   loader: () => import('view/buildingLevel2/form/BuildingLevel2FormPage'),
        //   menu: false,
        //   permissionRequired: permissions.buildingLevel2Create,
        //   exact: true,
        // },
        // {
        //   path: '/building-level2/importer',
        //   loader: () =>
        //     import('view/buildingLevel2/importer/BuildingLevel2ImporterPage'),
        //   menu: false,
        //   permissionRequired: permissions.buildingLevel2Import,
        //   exact: true,
        // },
        {
          path: '/building-level2/:id/edit',
          loader: () => import('view/buildingLevel2/form/BuildingLevel2FormPage'),
          menu: false,
          permissionRequired: permissions.buildingLevel2Edit,
          exact: true,
        },
        {
          path: '/building-level2/:id',
          loader: () => import('view/buildingLevel2/view/BuildingLevel2ViewPage'),
          menu: false,
          permissionRequired: permissions.buildingLevel2Read,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: true,
      label: i18n('subMenu.locations'),
      icon: 'menu',
      item: [
        {
          path: '/cluster',
          loader: () => import('view/cluster/list/ClusterListPage'),
          permissionRequired: permissions.clusterRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.cluster.menu'),
          menu: true,
          menuGroup: i18n('subMenu.locations'),
        },
        {
          path: '/cluster/new',
          loader: () => import('view/cluster/form/ClusterFormPage'),
          menu: false,
          permissionRequired: permissions.clusterCreate,
          exact: true,
        },
        {
          path: '/cluster/importer',
          loader: () =>
            import('view/cluster/importer/ClusterImporterPage'),
          menu: false,
          permissionRequired: permissions.clusterImport,
          exact: true,
        },
        {
          path: '/cluster/:id/edit',
          loader: () => import('view/cluster/form/ClusterFormPage'),
          menu: false,
          permissionRequired: permissions.clusterEdit,
          exact: true,
        },
        {
          path: '/cluster/:id',
          loader: () => import('view/cluster/view/ClusterViewPage'),
          menu: false,
          permissionRequired: permissions.clusterRead,
          exact: true,
        },

        // {
        //   path: '/clusterEbox',
        //   loader: () => import('view/clusterEbox/list/ClusterEboxListPage'),
        //   permissionRequired: permissions.clusterRead,
        //   exact: true,
        //   icon: 'right',
        //   label: i18n('entities.clusterEbox.menu'),
        //   menu: true,
        // },
        {
          path: '/cluster/clusterEbox/new/:idCluster',
          loader: () => import('view/clusterEbox/form/ClusterEboxFormPage'),
          menu: false,
          permissionRequired: permissions.clusterCreate,
          exact: true,
        },
        {
          path: '/cluster/clusterEbox/importer',
          loader: () =>
            import('view/clusterEbox/importer/ClusterEboxImporterPage'),
          menu: false,
          permissionRequired: permissions.clusterImport,
          exact: true,
        },
        {
          path: '/cluster/clusterEbox/:id/edit',
          loader: () => import('view/clusterEbox/form/ClusterEboxFormPage'),
          menu: false,
          permissionRequired: permissions.clusterEdit,
          exact: true,
        },
        {
          path: '/cluster/clusterEbox/:id',
          loader: () => import('view/clusterEbox/view/ClusterEboxViewPage'),
          menu: false,
          permissionRequired: permissions.clusterRead,
          exact: true,
        },
        {
          path: '/ebox',
          loader: () => import('view/ebox/list/EboxListPage'),
          permissionRequired: permissions.eboxRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.ebox.menu'),
          menu: true,
          menuGroup: i18n('subMenu.locations'),
        },
        {
          path: '/ebox/new',
          loader: () => import('view/ebox/form/EboxFormPage'),
          menu: false,
          permissionRequired: permissions.eboxCreate,
          exact: true,
        },
        {
          path: '/ebox/importer',
          loader: () =>
            import('view/ebox/importer/EboxImporterPage'),
          menu: false,
          permissionRequired: permissions.eboxImport,
          exact: true,
        },
        {
          path: '/ebox/:id/edit',
          loader: () => import('view/ebox/form/EboxFormPage'),
          menu: false,
          permissionRequired: permissions.eboxEdit,
          exact: true,
        },
        {
          path: '/ebox/:id/clone',
          loader: () => import('view/ebox/form/EboxCloneModal'),
          menu: false,
          permissionRequired: permissions.eboxEdit,
          exact: true,
        },
        {
          path: '/ebox/:id',
          loader: () => import('view/ebox/view/EboxViewPage'),
          menu: false,
          permissionRequired: permissions.eboxRead,
          exact: true,
        },
        // {
        //   path: '/compartiment',
        //   loader: () => import('view/compartiment/list/CompartimentListPage'),
        //   permissionRequired: permissions.compartimentRead,
        //   exact: true,
        //   icon: 'right',
        //   label: i18n('entities.compartiment.menu'),
        //   menu: true,
        // },
        {
          path: '/ebox/compartiment/new/:idEbox',
          loader: () => import('view/compartiment/form/CompartimentFormPage'),
          menu: false,
          permissionRequired: permissions.compartimentCreate,
          exact: true,
        },
        {
          path: '/ebox/compartiment/importer',
          loader: () =>
            import('view/compartiment/importer/CompartimentImporterPage'),
          menu: false,
          permissionRequired: permissions.compartimentImport,
          exact: true,
        },
        {
          path: '/ebox/compartiment/:id/edit',
          loader: () => import('view/compartiment/form/CompartimentFormPage'),
          menu: false,
          permissionRequired: permissions.compartimentEdit,
          exact: true,
        },
        {
          path: '/ebox/compartiment/:id',
          loader: () => import('view/compartiment/view/CompartimentViewPage'),
          menu: false,
          permissionRequired: permissions.compartimentRead,
          exact: true,
        },
        {
          path: '/group',
          loader: () => import('view/group/list/GroupListPage'),
          permissionRequired: permissions.groupRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.group.menu'),
          menu: true,
          menuGroup: i18n('subMenu.locations'),
        },
        {
          path: '/group/new',
          loader: () => import('view/group/form/GroupFormPage'),
          menu: false,
          permissionRequired: permissions.groupCreate,
          exact: true,
        },
        {
          path: '/group/importer',
          loader: () =>
            import('view/group/importer/GroupImporterPage'),
          menu: false,
          permissionRequired: permissions.groupImport,
          exact: true,
        },
        {
          path: '/group/:id/edit',
          loader: () => import('view/group/form/GroupFormPage'),
          menu: false,
          permissionRequired: permissions.groupEdit,
          exact: true,
        },
        {
          path: '/group/:id',
          loader: () => import('view/group/view/GroupViewPage'),
          menu: false,
          permissionRequired: permissions.groupRead,
          exact: true,
        },
        {
          path: '/eboxModule',
          loader: () => import('view/eboxModule/list/EboxModuleListPage'),
          permissionRequired: permissions.eboxModuleRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.eboxModule.menu'),
          menu: true,
          menuGroup: i18n('subMenu.locations'),
        },
        {
          path: '/eboxModule/new',
          loader: () => import('view/eboxModule/form/EboxModuleFormPage'),
          menu: false,
          permissionRequired: permissions.eboxModuleCreate,
          exact: true,
        },
        {
          path: '/eboxModule/importer',
          loader: () =>
            import('view/eboxModule/importer/EboxModuleImporterPage'),
          menu: false,
          permissionRequired: permissions.eboxModuleImport,
          exact: true,
        },
        {
          path: '/eboxModule/:id/edit',
          loader: () => import('view/eboxModule/form/EboxModuleFormPage'),
          menu: false,
          permissionRequired: permissions.eboxModuleEdit,
          exact: true,
        },
        {
          path: '/eboxModule/:id',
          loader: () => import('view/eboxModule/view/EboxModuleViewPage'),
          menu: false,
          permissionRequired: permissions.eboxModuleRead,
          exact: true,
        },

        // {
        //   path: '/eboxModuleCompartiment',
        //   loader: () => import('view/eboxModuleCompartiment/list/EboxModuleCompartimentListPage'),
        //   permissionRequired: permissions.eboxModuleRead,
        //   exact: true,
        //   icon: 'right',
        //   label: i18n('entities.eboxModuleCompartiment.menu'),
        //   menu: true,
        // },
        {
          path: '/eboxModule/eboxModuleCompartiment/new/:idModule',
          loader: () => import('view/eboxModuleCompartiment/form/EboxModuleCompartimentFormPage'),
          menu: false,
          permissionRequired: permissions.eboxModuleCreate,
          exact: true,
        },
        {
          path: '/eboxModule/eboxModuleCompartiment/importer',
          loader: () =>
            import('view/eboxModuleCompartiment/importer/EboxModuleCompartimentImporterPage'),
          menu: false,
          permissionRequired: permissions.eboxModuleCImport,
          exact: true,
        },
        {
          path: '/eboxModule/eboxModuleCompartiment/:id/edit',
          loader: () => import('view/eboxModuleCompartiment/form/EboxModuleCompartimentFormPage'),
          menu: false,
          permissionRequired: permissions.eboxModuledit,
          exact: true,
        },
        {
          path: '/eboxModule/eboxModuleCompartiment/:id',
          loader: () => import('view/eboxModuleCompartiment/view/EboxModuleCompartimentViewPage'),
          menu: false,
          permissionRequired: permissions.eboxModuleRead,
          exact: true,
        },
        {
          path: '/compartimentLayout',
          loader: () => import('view/compartimentLayout/list/CompartimentLayoutListPage'),
          permissionRequired: permissions.compartimentLayoutRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.compartimentLayout.menu'),
          menu: true,
          menuGroup: i18n('subMenu.locations'),
        },
        {
          path: '/compartimentLayout/new',
          loader: () => import('view/compartimentLayout/form/CompartimentLayoutFormPage'),
          menu: false,
          permissionRequired: permissions.compartimentLayoutCreate,
          exact: true,
        },
        {
          path: '/compartimentLayout/importer',
          loader: () =>
            import('view/compartimentLayout/importer/CompartimentLayoutImporterPage'),
          menu: false,
          permissionRequired: permissions.compartimentLayoutImport,
          exact: true,
        },
        {
          path: '/compartimentLayout/:id/edit',
          loader: () => import('view/compartimentLayout/form/CompartimentLayoutFormPage'),
          menu: false,
          permissionRequired: permissions.compartimentLayoutEdit,
          exact: true,
        },
        {
          path: '/compartimentLayout/:id',
          loader: () => import('view/compartimentLayout/view/CompartimentLayoutViewPage'),
          menu: false,
          permissionRequired: permissions.compartimentLayoutRead,
          exact: true,
        },
      ]
    }
  },

  {
    menuGroup: {
      isGroup: true,
      label: i18n('subMenu.terminal'),
      icon: 'menu',
      item: [
        {
          path: '/versions',
          loader: () => import('view/terminalUiVersion/list/VersionListPage'),
          permissionRequired: permissions.terminalUiVersionRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.version.menu'),
          menu: true,
          menuGroup: i18n('subMenu.terminal'),
        },
        {
          path: '/versions/new',
          loader: () => import('view/terminalUiVersion/form/VersionFormPage'),
          menu: false,
          permissionRequired: permissions.terminalUiVersionCreate,
          exact: true,
        },
        {
          path: '/versions/:id/edit',
          loader: () => import('view/terminalUiVersion/form/VersionFormPage'),
          menu: false,
          permissionRequired: permissions.terminalUiVersionEdit,
          exact: true,
        },
        {
          path: '/versions/:id',
          loader: () => import('view/terminalUiVersion/view/VersionViewPage'),
          menu: false,
          permissionRequired: permissions.terminalUiVersionRead,
          exact: true,
        },
        {
          path: '/release',
          loader: () => import('view/terminalUiRelease/list/ReleaseListPage'),
          permissionRequired: permissions.terminalUiReleaseRead,
          exact: true,
          icon: 'right',
          label: i18n('entities.release.menu'),
          menu: true,
          menuGroup: i18n('subMenu.terminal'),
        },
        {
          path: '/release/new',
          loader: () => import('view/terminalUiRelease/form/ReleaseFormPage'),
          menu: false,
          permissionRequired: permissions.terminalUiReleaseCreate,
          exact: true,
        },
        {
          path: '/release/:id/edit',
          loader: () => import('view/terminalUiRelease/form/ReleaseFormPage'),
          menu: false,
          permissionRequired: permissions.terminalUiReleaseCreate,
          exact: true,
        },
        {
          path: '/release/:id',
          loader: () => import('view/terminalUiRelease/view/ReleaseViewPage'),
          menu: false,
          permissionRequired: permissions.terminalUiReleaseRead,
          exact: true,
        }
      ]
    }
  },

  {
    menuGroup: {
      isGroup: true,
      label: i18n('subMenu.system'),
      icon: 'menu',
      item: [
        {
          path: '/iam',
          loader: () => import('view/iam/list/IamPage'),
          permissionRequired: permissions.iamRead,
          exact: true,
          icon: 'user-add',
          label: i18n('iam.menu'),
          menu: true,
          menuGroup: i18n('subMenu.locations'),
        },
        {
          path: '/iam/new',
          loader: () => import('view/iam/new/IamNewPage'),
          menu: false,
          permissionRequired: permissions.iamCreate,
          exact: true,
        },
        {
          path: '/iam/importer',
          loader: () =>
            import('view/iam/importer/IamImporterPage'),
          menu: false,
          permissionRequired: permissions.iamImport,
          exact: true,
        },
        {
          path: '/iam/:id/edit',
          loader: () => import('view/iam/edit/IamEditPage'),
          menu: false,
          permissionRequired: permissions.iamEdit,
          exact: true,
        },
        {
          path: '/iam/:id',
          loader: () => import('view/iam/view/IamViewPage'),
          menu: false,
          permissionRequired: permissions.iamRead,
          exact: true,
        },
        {
          path: '/role',
          loader: () => import('view/role/list/RoleListPage'),
          permissionRequired: permissions.roleRead,
          exact: true,
          icon: 'profile',
          label: i18n('entities.role.menu'),
          menu: true
        },
        {
          path: '/role/new',
          loader: () => import('view/role/form/RoleFormPage'),
          menu: false,
          permissionRequired: permissions.roleCreate,
          exact: true,
        },
        {
          path: '/role/:id/edit',
          loader: () => import('view/role/form/RoleFormPage'),
          menu: false,
          permissionRequired: permissions.roleEdit,
          exact: true,
        },
        {
          path: '/role/:id',
          loader: () => import('view/role/view/RoleViewPage'),
          menu: false,
          permissionRequired: permissions.roleRead,
          exact: true,
        },
        {
          path: '/audit-logs',
          icon: 'file-search',
          label: i18n('auditLog.menu'),
          loader: () => import('view/auditLog/AuditLogPage'),
          menu: true,
          menuGroup: i18n('subMenu.locations'),
          permissionRequired: permissions.auditLogRead,
        },
        {
          path: '/settings',
          icon: 'setting',
          label: i18n('settings.menu'),
          loader: () => import('view/settings/SettingsFormPage'),
          permissionRequired: permissions.settingsEdit,
          menu: true,
          menuGroup: i18n('subMenu.locations'),
        },
      ]
    }
  },

];

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () => import('view/auth/ForgotPasswordPage'),
  },
];

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () => import('view/auth/EmptyPermissionsPage'),
  },
];

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () => import('view/auth/EmailUnverifiedPage'),
  },
];

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () => import('view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () => import('view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () => import('view/shared/errors/Error404Page'),
  },
];

export default {
  privateRoutes,
  publicRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
