import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/routePacking/importer/routeImporterSelectors';
import RouteService from 'modules/routePacking/routeService';
import fields from 'modules/routePacking/importer/routeImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'ROUTE_IMPORTER',
  selectors,
  RouteService.import,
  fields,
  i18n('entities.route.importer.fileName'),
);
