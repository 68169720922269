
import { i18n } from 'i18n';
import _values from 'lodash/values';
class Permissions {
  static get values() {
    return {
      iamEdit: 'iamEdit',
      iamCreate: 'iamCreate',
      iamImport: 'iamImport',
      iamRead: 'iamRead',
      iamUserAutocomplete: 'iamUserAutocomplete',
      auditLogRead: 'auditLogRead',
      settingsEdit: 'settingsEdit',
      customerImport: 'customerImport',
      customerCreate: 'customerCreate',
      customerEdit: 'customerEdit',
      customerDestroy: 'customerDestroy',
      customerRead: 'customerRead',
      customerAutocomplete: 'customerAutocomplete',
      productImport: 'productImport',
      productCreate: 'productCreate',
      productEdit: 'productEdit',
      productDestroy: 'productDestroy',
      productRead: 'productRead',
      productAutocomplete: 'productAutocomplete',
      paymentCreate: 'paymentCreate',
      paymentEdit: 'paymentEdit',
      paymentDestroy: 'paymentDestroy',
      paymentRead: 'paymentRead',
      orderImport: 'orderImport',
      orderCreate: 'orderCreate',
      orderEdit: 'orderEdit',
      orderResendSMS: 'orderResendSMS',
      orderUpdateOverdue: 'orderUpdateOverdue',
      orderUpdateStatus: 'orderUpdateStatus',
      orderDestroy: 'orderDestroy',
      orderRead: 'orderRead',
      orderAutocomplete: 'orderAutocomplete',
      orderQrcode: 'orderQrcode',
      orderExport: 'orderExport',
      orderInbound: 'orderInbound',
      generateTag: 'generateTag',
      companyImport: 'companyImport',
      companyCreate: 'companyCreate',
      companyEdit: 'companyEdit',
      companyDestroy: 'companyDestroy',
      companyRead: 'companyRead',
      companyAutocomplete: 'companyAutocomplete',
      companyUserCreate: 'companyUserCreate',
      roleCreate: 'roleCreate',
      roleEdit: 'roleEdit',
      roleDestroy: 'roleDestroy',
      roleRead: 'roleRead',
      roleAutocomplete: 'roleAutocomplete',
      roleUserCreate: 'roleUserCreate',
      routeImport: 'routeImport',
      routeCreate: 'routeCreate',
      routeEdit: 'routeEdit',
      routeDestroy: 'routeDestroy',
      routeRead: 'routeRead',
      routeAutocomplete: 'routeAutocomplete',
      eboxImport: 'eboxImport',
      eboxCreate: 'eboxCreate',
      eboxEdit: 'eboxEdit',
      eboxDestroy: 'eboxDestroy',
      eboxRead: 'eboxRead',
      filterEbox: 'filterEbox',
      filterType: 'FilterType',
      filterReferenceNumber: 'FilterReferenceNumber',
      eboxAutocomplete: 'eboxAutocomplete',
      clusterImport: 'clusterImport',
      clusterCreate: 'clusterCreate',
      clusterEdit: 'clusterEdit',
      clusterDestroy: 'clusterDestroy',
      clusterRead: 'clusterRead',
      filterCluster: 'filterCluster',
      clusterAutocomplete: 'clusterAutocomplete',
      eboxModuleImport: 'eboxModuleImport',
      eboxModuleCreate: 'eboxModuleCreate',
      eboxModuleEdit: 'eboxModuleEdit',
      eboxModuleDestroy: 'eboxModuleDestroy',
      eboxModuleRead: 'eboxModuleRead',
      eboxModuleAutocomplete: 'eboxModuleAutocomplete',
      eboxReboot: 'eboxReboot',
      eboxRestartApp: 'eboxRestartApp',
      eboxSync: 'eboxSync',
      compartimentLayoutImport: 'compartimentLayoutImport',
      compartimentLayoutCreate: 'compartimentLayoutCreate',
      compartimentLayoutEdit: 'compartimentLayoutEdit',
      compartimentLayoutDestroy: 'compartimentLayoutDestroy',
      compartimentLayoutRead: 'compartimentLayoutRead',
      compartimentLayoutAutocomplete: 'compartimentLayoutAutocomplete',
      groupImport: 'groupImport',
      groupCreate: 'groupCreate',
      groupEdit: 'groupEdit',
      groupDestroy: 'groupDestroy',
      groupRead: 'groupRead',
      groupAutocomplete: 'groupAutocomplete',
      packingCreate: 'packingCreate',
      packingDestroy: 'packingDestroy',
      packingRead: 'packingRead',
      packingAutocomplete: 'packingAutocomplete',
      inboundCreate: 'inboundCreate',
      inboundRead: 'inboundRead',
      inboundAutocomplete: 'inboundAutocomplete',
      invoiceRead: 'invoiceRead',
      compartimentImport: 'compartimentImport',
      compartimentCreate: 'compartimentCreate',
      compartimentEdit: 'compartimentEdit',
      compartimentDestroy: 'compartimentDestroy',
      compartimentRead: 'compartimentRead',
      compartimentAutocomplete: 'compartimentAutocomplete',
      compartimentOpen: 'compartimentOpen',
      compartimentLock: 'compartimentLock',
      webhookImport: 'webhookImport',
      webhookCreate: 'webhookCreate',
      webhookEdit: 'webhookEdit',
      webhookDestroy: 'webhookDestroy',
      webhookRead: 'webhookRead',
      webhookAutocomplete: 'webhookAutocomplete',
      ccmImport: 'ccmImport',
      ccmCreate: 'ccmCreate',
      ccmEdit: 'ccmEdit',
      ccmDestroy: 'ccmDestroy',
      ccmRead: 'ccmRead',
      ccmAutocomplete: 'ccmAutocomplete',
      qrcodeTemplateImport: 'qrcodeTemplateImport',
      qrcodeTemplateCreate: 'qrcodeTemplateCreate',
      qrcodeTemplateEdit: 'qrcodeTemplateEdit',
      qrcodeTemplateDestroy: 'qrcodeTemplateDestroy',
      qrcodeTemplateRead: 'qrcodeTemplateRead',
      qrcodeTemplateAutocomplete: 'qrcodeTemplateAutocomplete',
      warehouseImport: 'warehouseImport',
      warehouseCreate: 'warehouseCreate',
      warehouseEdit: 'warehouseEdit',
      warehouseDestroy: 'warehouseDestroy',
      warehouseRead: 'warehouseRead',
      warehouseAutocomplete: 'warehouseAutocomplete',
      buildingImport: 'buildingImport',
      buildingCreate: 'buildingCreate',
      buildingEdit: 'buildingEdit',
      buildingDestroy: 'buildingDestroy',
      buildingRead: 'buildingRead',
      buildingAutocomplete: 'buildingAutocomplete',
      buildingLevel1Import: 'buildingLevel1Import',
      buildingLevel1Create: 'buildingLevel1Create',
      buildingLevel1Edit: 'buildingLevel1Edit',
      buildingLevel1Destroy: 'buildingLevel1Destroy',
      buildingLevel1Read: 'buildingLevel1Read',
      buildingLevel1Autocomplete: 'buildingLevel1Autocomplete',
      buildingLevel2Import: 'buildingLevel2Import',
      buildingLevel2Create: 'buildingLevel2Create',
      buildingLevel2Edit: 'buildingLevel2Edit',
      buildingLevel2Destroy: 'buildingLevel2Destroy',
      buildingLevel2Read: 'buildingLevel2Read',
      buildingLevel2Autocomplete: 'buildingLevel2Autocomplete',
      terminalOrderExpiredCollect: 'terminalOrderExpiredCollect',
      terminalOrderReturnedCollect: 'terminalOrderReturnedCollect',
      terminalOrderStore: 'terminalOrderStore',
      terminalCompartimentRead: 'terminalCompartimentRead',
      terminalCompartimentEdit: 'terminalCompartimentEdit',
      terminalCompartimentTest: 'terminalCompartimentTest',
      terminalCompartimentOpen: 'terminalCompartimentOpen',
      terminalUpdateVersionInit: 'terminalUpdateVersionInit',
      terminalReboot: 'terminalReboot',
      terminalRestartApp: 'terminalRestartApp',
      appTerminalMigration: 'appTerminalMigration',
      appTerminalMigrationToggle: 'appTerminalMigrationToggle',
      appTerminalCompartimentRead: 'appTerminalCompartimentRead',
      appTerminalCompartimentEdit: 'appTerminalCompartimentEdit',
      appTerminalOrderRead: 'appTerminalOrderRead',
      appTerminalOrderEdit: 'appTerminalOrderEdit',
      appTerminalOrderCreate: 'appTerminalOrderCreate',
      appTerminalAdmQrCode: 'appTerminalAdmQrCode',
      appTerminalEboxRead: 'appTerminalEboxRead',
      appTerminalEboxEdit: 'appTerminalEboxEdit',
      appTerminalEboxCreate: 'appTerminalEboxCreate',
      appTerminalEboxConfig: 'appTerminalEboxConfig',
      appTerminalConfigApp: 'appTerminalConfigApp',
      terminalUiReleaseCreate: 'terminalUiReleaseCreate',
      terminalUiReleaseEdit: 'terminalUiReleaseEdit',
      terminalUiReleaseDestroy: 'terminalUiReleaseDestroy',
      terminalUiReleaseRead: 'terminalUiReleaseRead',
      terminalUiReleaseAutocomplete: 'terminalUiReleaseAutocomplete',
      terminalUiVersionCreate: 'terminalUiVersionCreate',
      terminalUiVersionEdit: 'terminalUiVersionEdit',
      terminalUiVersionDestroy: 'terminalUiVersionDestroy',
      terminalUiVersionRead: 'terminalUiVersionRead',
      terminalUiVersionAutocomplete: 'terminalUiVersionAutocomplete',
      terminalCollectPending: 'terminalCollectPending',
      uploadFiles: 'uploadFiles',
      terminalShowEboxStatus: 'terminalShowEboxStatus',
      terminalOperatorCondominium: 'terminalOperatorCondominium',
      terminalShowPendingDepositOperator: 'terminalShowPendingDepositOperator',
      terminalMaintenance: 'terminalMaintenance',
      operationAutocomplete: 'operationAutocomplete',
      operationCreate: 'operationCreate',
      operationEdit: 'operationEdit',
      operationDestroy: 'operationDestroy',
      operationRead: 'operationRead',
      paymentGatewaysAutocomplete: 'paymentGatewaysAutocomplete',
      paymentGatewaysCreate: 'paymentGatewaysCreate',
      paymentGatewaysEdit: 'paymentGatewaysEdit',
      paymentGatewaysDestroy: 'paymentGatewaysDestroy',
      paymentGatewaysRead: 'paymentGatewaysRead',
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
  static labelOf(permission) {
    if (!this.values[permission]) {
      return permission;
    }

    return i18n(`permissions.${permission}.label`);
  }

  static descriptionOf(permission) {
    if (!this.values[permission]) {
      return permission;
    }

    return i18n(`permissions.${permission}.description`);
  }

  static get selectOptions() {
    return _values(this.values).map((value) => ({
      id: value,
      value: value,
      title: this.descriptionOf(value),
      label: this.labelOf(value),
    }));
  }
}

export default Permissions;
