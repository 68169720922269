import model from 'modules/routePacking/routeModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.notes,
  fields.routePackingEbox,
  fields.operatedBy,
  fields.createdAt,
  fields.updatedAt
];
